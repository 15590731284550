[
    {
        "address_1": "Unit E Valegate Retail Park",
        "address_2": "Copthorne Way",
        "city": "Cardiff",
        "country": "Wales",
        "county": "",
        "latitude": "-3.2731",
        "longitude": "51.4633",
        "name": "Axminster Tools - Cardiff",
        "postcode": "CF5 6EH",
        "telephone": "029 20025000",
        "url": "https://www.axminstertools.com/"
    },
    {
        "address_1": "Cressex Business Park",
        "address_2": "Lincoln Road",
        "city": "High Wycombe",
        "country": "England",
        "county": "Bucks",
        "latitude": "-0.7722",
        "longitude": "51.6212",
        "name": "Axminster Tools - High Wycombe",
        "postcode": "HP12 3FF",
        "telephone": "01494 885480",
        "url": "https://www.axminstertools.com/"
    },
    {
        "address_1": "Unit 9A",
        "address_2": "Weycroft Avenue",
        "city": "Axminster",
        "country": "England",
        "county": "Devon",
        "latitude": "-2.981",
        "longitude": "50.7901",
        "name": "Axminster Tools - Axminster",
        "postcode": "EX13 5PH",
        "telephone": "01297 302370",
        "url": "https://www.axminstertools.com/"
    },
    {
        "address_1": "Sheppey Way",
        "address_2": "Bobbing",
        "city": "Sittingbourne",
        "country": "England",
        "county": "Kent",
        "latitude": "0.7207",
        "longitude": "51.3612",
        "name": "Axminster Tools - Sittingbourne",
        "postcode": "ME9 8QP",
        "telephone": "01795 437143",
        "url": "https://www.axminstertools.com/"
    },
    {
        "address_1": "24 Colliergate",
        "address_2": "",
        "city": "York",
        "country": "England",
        "county": "North Yorkshire",
        "latitude": "-1.0795",
        "longitude": "53.9599",
        "name": "Barnitts Ltd",
        "postcode": "YO1 8BW",
        "telephone": "01904 625601",
        "url": "https://www.barnitts.co.uk/"
    },
    {
        "address_1": "1 Beatrice Street",
        "address_2": "",
        "city": "Swindon",
        "country": "England",
        "county": "Wiltshire",
        "latitude": "-1.7823",
        "longitude": "51.5692",
        "name": "Beesleys",
        "postcode": "SN2 1BB",
        "telephone": "01793 538847",
        "url": "https://www.tool-shop.co.uk/"
    },
    {
        "address_1": "49 Hatton Garden",
        "address_2": "",
        "city": "",
        "country": "England",
        "county": "London",
        "latitude": "-0.1083",
        "longitude": "51.5212",
        "name": "Cooksongold - London",
        "postcode": "EC1N 8YS",
        "telephone": "0345 100 1122",
        "url": "https://www.cooksongold.com/"
    },
    {
        "address_1": "59-83 Vittoria Street",
        "address_2": "Jewellery Quarter",
        "city": "",
        "country": "England",
        "county": "Birmingham",
        "latitude": "-1.9104",
        "longitude": "52.4864",
        "name": "Cooksongold - Birmingham",
        "postcode": "B1 3NZ",
        "telephone": "0345 100 1122",
        "url": "https://www.cooksongold.com/"
    },
    {
        "address_1": "79-81 Kingsland Road",
        "address_2": "Shoreditch",
        "city": "London",
        "country": "England",
        "county": "East London",
        "latitude": "-0.0776",
        "longitude": "51.5302",
        "name": "C W Tyzack",
        "postcode": "E2 8AH",
        "telephone": "020 77397126",
        "url": "https://www.tyzacktools.com/"
    },
    {
        "address_1": "Unit 2 Southfields Road",
        "address_2": "Dunstable",
        "city": "",
        "country": "England",
        "county": "Bedfordshire",
        "latitude": "-0.509",
        "longitude": "51.8768",
        "name": "Chronos Ltd",
        "postcode": "LU6 3EJ",
        "telephone": "01582 471900",
        "url": "https://www.chronos.ltd.uk/"
    },
    {
        "address_1": "Alpaca Centre",
        "address_2": "Stainton",
        "city": "Penrith",
        "country": "England",
        "county": "Cumbria",
        "latitude": "-2.8017",
        "longitude": "54.6425",
        "name": "G & S Specialist Timber",
        "postcode": "CA11 0ES",
        "telephone": "01768 891445",
        "url": "https://www.toolsandtimber.co.uk/"
    },
    {
        "address_1": "Unit 8, The Raveningham Centre",
        "address_2": "Beccles Road",
        "city": "Raveningham",
        "country": "England",
        "county": "Norfolk",
        "latitude": "1.542",
        "longitude": "52.5049",
        "name": "Hobbies Ltd.",
        "postcode": "NR14 6NU",
        "telephone": "01508 549330",
        "url": "https://www.hobbies.co.uk/"
    },
    {
        "address_1": "44 Hatton Garden",
        "address_2": "",
        "city": "London",
        "country": "England",
        "county": "",
        "latitude": "-0.1084",
        "longitude": "51.5208",
        "name": "H S Walsh - London",
        "postcode": "EC1N 8ER",
        "telephone": "01959 543 660",
        "url": "https://www.hswalsh.com/"
    },
    {
        "address_1": "1-2 Warstone Mews",
        "address_2": "",
        "city": "Birmingham",
        "country": "England",
        "county": "",
        "latitude": "-1.9106",
        "longitude": "52.4876",
        "name": "H S Walsh - Birmingham",
        "postcode": "B18 6JB",
        "telephone": "01959 543 660",
        "url": "hswalsh.com"
    },
    {
        "address_1": "Caldene Business Park",
        "address_2": "Burnley Road",
        "city": "Hebden Bridge",
        "country": "England",
        "county": "West Yorkshire",
        "latitude": "-1.9863",
        "longitude": "53.7326",
        "name": "RDG Tools",
        "postcode": "HX7 5AD",
        "telephone": "01422 885069",
        "url": "https://www.rdgtools.co.uk/"
    },
    {
        "address_1": "100 London Rd",
        "address_2": "",
        "city": "Bognor Regis",
        "country": "England",
        "county": "West Sussex",
        "latitude": "-0.6744",
        "longitude": "50.7867",
        "name": "Squires",
        "postcode": "PO21 1DD",
        "telephone": "01243 842424",
        "url": "http://www.squirestools.com/"
    },
    {
        "address_1": "Unit D4 M7 Business Park",
        "address_2": "Newhall",
        "city": "Nass",
        "country": "Ireland",
        "county": "County Kildare",
        "latitude": "-6.70754597277779",
        "longitude": "53.2160657929152",
        "name": "The Carpentry Store",
        "postcode": "",
        "telephone": "353(87)247 4343",
        "url": "https://www.thecarpentrystore.com/"
    },
    {
        "address_1": "Hurst Works",
        "address_2": "",
        "city": "Martock",
        "country": "England",
        "county": "Somerset",
        "latitude": "-2.7735",
        "longitude": "50.9652",
        "name": "Yandles of Martock",
        "postcode": "TA12 6JU",
        "telephone": "01935 822207",
        "url": "https://www.yandles.co.uk/"
    },
    {
        "address_1": "Beacon Kilns",
        "address_2": "High Street Village",
        "city": "St. Austell",
        "country": "England",
        "county": "Cornwall",
        "latitude": "-4.8626",
        "longitude": "50.3402",
        "name": "Westcountry Machinery 4 Wood",
        "postcode": "PL26 7SR",
        "telephone": "01726 828422",
        "url": "https://www.machinery4wood.co.uk/"
    },
    {
        "address_1": "20 Fitzwilliam Street",
        "address_2": "",
        "city": "Bradford",
        "country": "England",
        "county": "West Yorkshire",
        "latitude": "-1.7479",
        "longitude": "53.7873",
        "name": "A.C. Sissling",
        "postcode": "BD4 7BL",
        "telephone": "01274 200 320",
        "url": "https://www.acsissling.com/"
    }
]
